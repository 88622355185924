<template>
  <div>
    <v-dialog
      :value="show"
      width="500"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          Change owner
        </v-card-title>
        <v-card-text class="mt-4">
          <b class="d-block my-3">
            {{ project.creator.name }} will be removed from the project.
          </b>
        </v-card-text>
        <v-card-text>
          <select-users
            ref="newOwner"
            v-model="newOwner"
            label="New owner"
            :rules="[rules.required]"
            :multiple="false"
            :source="allUsers"
          />
        </v-card-text>
        <v-card-text>
          <v-autocomplete
            v-if="missingUnit"
            v-model="userUnit"
            :items="units"
            item-text="name"
            item-value="id"
            label="Project Unit *"
            no-data-text="No units available"
            placeholder="Select an unit..."
            return-object
          />
        </v-card-text>
        <v-card-actions class="pb-3 pr-3">
          <v-spacer />
          <app-button
            secondary
            color="red"
            @click="close"
          >
            Cancel
          </app-button>
          <app-button
            :disabled="!newOwner"
            @click="changeOwner"
          >
            Confirm
          </app-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SelectUsers } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import projectService from '@/services/projectService';
import { userService } from '@/services';

export default {
  name: 'ChangeOwner',
  components: {
    SelectUsers
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      newOwner: null,
      rules: {
        required: (value) => !!value || 'The field is required.'
      },
      allUsers: [],
      userUnit: null,
      userPhoneNumber: '',
      missingUnit: false
    };
  },
  computed: {
    ...mapGetters(['users', 'units'])
  },
  watch: {
    newOwner() {
      const { newOwner } = this;
      if (newOwner) {
        if (!newOwner.unit) {
          this.userUnit = this.project.projectUnit;
          this.missingUnit = true;
        } else {
          this.userUnit = newOwner.unit;
        }
        if (!newOwner.contactInfo?.length) {
          this.userPhoneNumber = '00000';
        }
      } else {
        this.userUnit = null;
        this.userPhoneNumber = '';
        this.missingUnit = false;
      }
    }
  },
  async created() {
    this.allUsers = await userService.getChangeOwnerUsers(this.project.creator.email);
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) { // Escape key
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async changeOwner() {
      this.$refs.newOwner.validate(true);
      if (this.newOwner) {
        const project = await projectService.changeProjectOwner({
          projectId: this.project.id,
          newOwnerId: this.newOwner.id,
          userUnitId: this.userUnit.id,
          userPhoneNumber: this.userPhoneNumber
        });
        this.$emit('confirm', project);
        this.close();
      }
    }
  }
};
</script>
