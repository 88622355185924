<template>
  <div class="text-center">
    <a
      class="view-dates"
      @click.stop="getDates"
    >View dates</a>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          Email Coaching Dates
        </v-card-title>
        <v-row
          class="ma-4 title-container"
          no-gutters
        >
          <v-col
            v-if="subscribed"
            cols="2"
          >
            Sent
          </v-col>
          <v-col cols="6">
            Email Coaching Name
          </v-col>
          <v-col class="pl-2">
            Date
          </v-col>
        </v-row>
        <v-row
          v-for="item in items"
          :key="item.id"
          class="ma-4"
          no-gutters
        >
          <v-col
            v-if="subscribed"
            cols="2"
          >
            <v-icon v-if="item.sent">
              check
            </v-icon>
          </v-col>
          <v-col
            :class="{'item-deleted': item.deleted}"
            cols="6"
          >
            {{ item.name }}
          </v-col>
          <v-col
            :class="{'item-deleted': item.deleted}"
            class="pl-2"
          >
            {{ item.date }}
          </v-col>
        </v-row>
        <v-row
          v-if="items.length === 0"
          class="mt-6 mb-6"
        >
          <v-col class="text-center">
            <label>For old subscriptions this table is empty.</label>
          </v-col>
        </v-row>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <app-button @click="dialog = false">
            Done
          </app-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import emailCoachingService from '@/services/emailCoachingService';
import emailCoachingLogService from '@/services/emailCoachingLogService';

export default {
  props: [
    'startDate',
    'subscribed',
    'project',
    'coachingNumber',
  ],
  data() {
    return {
      dialog: false,
      items: [],
    };
  },
  methods: {
    async getDates() {
      const emailCoachingItemsArray = (await emailCoachingService.getEmailCoaching()).items;
      if (!this.subscribed) {
        emailCoachingItemsArray.splice(0, this.coachingNumber - 1);
        const deltaArray = emailCoachingItemsArray.map((ec) => ec.delta);
        const deltaArrayFormatted = deltaArray.map((d, index, array) => Math.abs(d - array[0]));
        const date = new Date(this.startDate);
        const daysInMilliseconds = 86400000;
        this.items = emailCoachingItemsArray.map((item, index) => ({
          id: item.key,
          name: item.name,
          date: moment(date.getTime() + (deltaArrayFormatted[index] * daysInMilliseconds)).format('DD MMM YYYY')
        }));
      } else {
        const emailCoachingLogArray = await emailCoachingLogService
          .getEmailCoachingLog(this.project.id);
        this.items = emailCoachingLogArray.map((ecl) => ({
          id: ecl.get('templateId'),
          name: ecl.get('templateName'),
          date: ecl.get('sentDate') ? moment(ecl.get('sentDate')).format('DD MMM YYYY')
            : moment(ecl.get('scheduleDate')).format('DD MMM YYYY'),
          sent: !!ecl.get('sentDate'),
          deleted: !emailCoachingItemsArray.some((ec) => ec.key === ecl.get('templateId'))
        }));
      }
      this.dialog = true;
    }
  }
};
</script>

<style>
  .view-dates {
    color: #0000008a;
    margin-left: 20px;
    margin-top: 20px;
    display: inline-block;
    font-size: 13px;
    text-decoration: underline;
  }
  .title-container {
    font-weight: bold;
  }
  .item-deleted {
    text-decoration: line-through;
  }
</style>
