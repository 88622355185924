<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <div
        v-on="isDisabled ? on : undefined"
        @click="clickButton"
      >
        <div
          v-if="fromBoard"
          class="v-list-item v-list-item--link theme--light"
        >
          <div :class="{'disabled' : isDisabled }">
            Change Owner
          </div>
        </div>
        <a
          v-else
          class="change-owner"
          :class="{'disabled' : isDisabled }"
        >
          Change owner
        </a>
      </div>
    </template>
    <span> {{ toolTipLabel }}</span>
  </v-tooltip>
</template>

<script>
import { userService } from '@/services';

export default {
  name: 'ChangeOwnerOption',
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    fromBoard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      users: []
    };
  },
  computed: {
    toolTipLabel() {
      return 'This requires at least 1 user with a verified email address';
    },
    isDisabled() {
      return !this.users.length;
    }
  },
  async created() {
    this.users = await userService.getChangeOwnerUsers(this.project.creator.email);
  },
  methods: {
    clickButton() {
      if (!this.isDisabled) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style scoped>
  .disabled {
    color: grey;
    cursor: initial;
  }
  .change-owner {
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 13px;
    text-decoration: underline;
  }
</style>
