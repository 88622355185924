<template>
  <div>
    <v-checkbox
      v-model="emailCampaignCheck"
      :disabled="disabled"
      label="Subscribe to email coaching"
      color="primary"
      class="check-box"
      hide-details
      @change="$emit('campaignSelected', emailCampaignCheck)"
    />
    <div v-if="!hadEmailCampaign && emailCampaignCheck">
      <div
        class="email-campaign-label mt-3"
      >
        The emails coaching will be sent starting from:
      </div>
      <v-row align-content="space-around">
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="campaignDateSelected"
            persistent
            width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="campaignDateSelected"
                prepend-icon="event"
                readonly
                class="pa-0"
                v-on="on"
                @click:prepend="on.click"
              />
            </template>
            <v-date-picker
              v-model="campaignDateSelected"
              :min="campaignMinDay"
            >
              <v-spacer />
              <app-button
                secondary
                cancel
                @click="modal = false"
              >
                Cancel
              </app-button>
              <app-button @click="save">
                OK
              </app-button>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          v-if="showCoachingNumber"
          cols="2"
          class="coaching-container"
        >
          <v-select
            v-model="coachingNumber"
            :items="coachingNumberArray"
            single-line
            hint="Coaching #"
            class="ma-0"
            :placeholder="coachingNumber ? undefined : 'Select'"
            persistent-hint
          />
        </v-col>
        <v-col cols="3">
          <email-campaign-dates
            :coaching-number="coachingNumber"
            :start-date="campaignDateSelected"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import emailCoachingService from '@/services/emailCoachingService';
import EmailCampaignDates from './EmailCampaignDates';
// This component could be split in 2, just leave here the checkbox input,
// the second component should return an object
// this should be done in https://atlassian.ngti.nl/browse/KP-744

export default {
  name: 'EmailCampaign',
  components: {
    EmailCampaignDates
  },
  props: {
    emailCampaign: {
      type: Boolean,
      default: false
    },
    hadEmailCampaign: {
      type: Boolean,
      default: false
    },
    showCoachingNumber: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emailCampaignCheck: false,
      campaignDateSelected: new Date().toISOString().substr(0, 10),
      campaignMinDay: new Date().toISOString().substr(0, 10),
      modal: false,
      coachingNumber: 1,
      coachingNumberArray: [],
    };
  },
  watch: {
    coachingNumber() {
      this.$emit('coachingNumber', this.coachingNumber);
    },
    campaignDateSelected() {
      this.$emit('campaignDateSelected', this.campaignDateSelected);
    },
    emailCampaignCheck() {
      this.$emit('campaignSelected', this.emailCampaignCheck);
    },
    emailCampaign() {
      this.emailCampaignCheck = this.emailCampaign;
    }
  },
  async created() {
    this.emailCampaignCheck = this.emailCampaign;
    const emailCoachingItems = await emailCoachingService.getEmailCoachingItems();
    this.coachingNumberArray = Array.from({ length: emailCoachingItems.length }, (v, i) => i + 1);
  },
  methods: {
    save() {
      this.modal = false;
      this.$refs.dialog.save(this.campaignDateSelected);
    }
  }
};
</script>

<style scoped>
  .email-campaign-label {
    color: #0000008a;
    font-size: .875rem;
  }

  .coaching-container {
    margin-left: 40px;
  }
</style>
