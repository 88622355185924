<template>
  <div class="pa-0 team-members-list-container">
    <v-card
      v-if="showList"
      v-click-outside="hideList"
      width="100%"
      class="team-members-list"
    >
      <v-list
        v-for="(item, index) in items"
        :key="index"
      >
        <v-row no-gutters>
          <v-col cols="2">
            <v-row
              no-gutters
              justify="center"
            >
              <v-list-item-avatar
                class="avatar"
                :style="{backgroundImage: `url(${getUserImage(item.photo)}`}"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text text--darken-2">
                {{ item.username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <v-list-item-content>
              <v-list-item-title>
                Status: {{ item.status }}
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text text--darken-2">
                Unit: {{ getUnit(item.unit) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list>
    </v-card>
    <label class="caption d-block grey--text">Team Members</label>
    <div class="my-3">
      <v-chip
        v-for="item in items"
        :key="item.id"
        pill
        @click="showList = !showList"
      >
        <v-avatar left>
          <div
            class="avatar"
            :style="{backgroundImage: `url(${getUserImage(item.photo)}`}"
          />
        </v-avatar>
        {{ item.name }}
      </v-chip>
      <label v-if="!items.length">
        No team members
      </label>
    </div>
  </div>
</template>

<script>
import noAvatar from '@/assets/img/no-avatar-icon.svg';

export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      showList: false
    };
  },
  methods: {
    getUserImage(photo) {
      return photo || noAvatar;
    },
    hideList() {
      this.showList = false;
    },
    getUnit(unit) {
      return unit || 'No unit';
    }
  }
};
</script>

<style lang="scss" scoped>
  .v-chip {
    margin: 0.3rem;
  }

  .avatar{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
  }

  .team-members-list-container {
    position: relative;

    .team-members-list{
      position: absolute;
      top: 5rem;
      z-index: 1;
      max-height: 280px;
      overflow-y: scroll;
    }

     ::v-deep .avatar {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
    }
    ::v-deep .v-list {
      padding: 2px 0;
    }
    ::v-deep .col-2 {
      max-width: 80px;
    }
    ::v-deep .v-list-item__avatar:first-child {
      margin-right: 0;
    }
  }
</style>
